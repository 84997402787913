<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 9L16.6667 16.6667" :stroke="stroke" />
    <path d="M17 9L9.33333 16.6667" :stroke="stroke" />
    <rect
      width="26"
      height="26"
      rx="13"
      :fill="fill"
      :fill-opacity="fillOpacity"
    />
  </svg>
</template>

<script>
export default {
  name: "PopupClose",
  props: {
    width: {
      type: [Number, String],
      default: 26,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    fill: {
      type: String,
      default: "#473068",
    },
    fillOpacity: {
      type: [Number, String],
      default: "0.1",
    },
    stroke: {
      type: String,
      default: "#473068",
    },
    viewBox: {
      type: [Number, String],
      default: "0 0 26 26",
    },
  },
};
</script>
